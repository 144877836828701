import { ApiStore, Widget, rotationModes } from '@hart-estate/widget';
import '../assets/sass/index.sass';

const BackendUrls = {
  production: 'https://backend.estate.hart-digital.com',
  stage: 'https://backend-stage.estate.hart-digital.com',
};
const DelayAfterLoad = 500;

const getParams = () => {
  const { searchParams } = new URL(document.location.toString());
  return {
    planId: searchParams.get('id'),
    crmPlanId: searchParams.get('crmPlanId'),
    env: searchParams.get('env') || 'production',
    primaryCameraPointId: searchParams.get('primaryCameraPointId'),
  };
};

const getJson = async (url) => {
  try {
    const res = await fetch(url, { method: 'GET', headers: { Accept: 'application/json' } });
    const json = await res.json();
    return [null, json];
  } catch (e) {
    return [e, null]
  }
};

const handleCustomParams = async (options, params) => {
  let newParams = params;
  if (typeof newParams === 'string') {
    const [error, result] = await getJson(newParams);
    return error ? console.error(error) : handleCustomParams(options, result);
  }

  if (!newParams) return;

  const keys = Object.keys(newParams);
  const excludedKeys = [
    'logo', 'logoUrl', 'locale', 'API_URL', 'width', 'height', 'json', 'branding', 'floors', 'primaryCameraPointId',
  ];
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (!excludedKeys.includes(key)) {
      options[key] = newParams[key];
    }
  }
};

const handleBrandingParams = async (options) => {
  const { branding } = options;
  if (branding == null) return;

  const { name, custom_style, custom_params } = branding;

  if (name != null) document.title = name;

  if (custom_style != null) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = custom_style;
    document.head.appendChild(link);
  }

  if (custom_params != null) {
    await handleCustomParams(options, custom_params);
  }
};

const createWidget = async () => {
  const { planId, crmPlanId, env, primaryCameraPointId } = getParams();

  const apiUrl = BackendUrls[env];
  const apiHandler = new ApiStore(apiUrl);

  const planData = crmPlanId
    ? await apiHandler.loadCrmWidgetData(crmPlanId)
    : await apiHandler.loadWidgetData(planId);

  const options = {
    ...planData.parsed,
    API_URL: apiUrl,
    rotationMode: rotationModes.DEFAULT,
    tabs: ['panorama', 'rotation'],
    integrations: {
      sentry: {
        dsn: 'https://62f653a9fc5368353dd83fba4017f1dd@sentry.hart-digital.com/16',
      },
    },
    primaryCameraPointId,
  };

  await handleBrandingParams(options);

  new Widget('#widget', options);
};

const hidePreloader = () => {
  const preloader = document.querySelector('.widget-preloader-frame');
  if (preloader) preloader.classList.add('hidden');
};

createWidget().then(() => setTimeout(hidePreloader, DelayAfterLoad));
